import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SidemenuItem.css'

const SidemenuItem = ({label, icon, onClick}) => {
    return (
        <div className='sidemenu--item' onClick={onClick}>
            {icon && <FontAwesomeIcon icon={icon} className='icon' />}
            {label}
        </div>
    );
}

export default SidemenuItem;