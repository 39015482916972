import React from 'react';
import SidemenuItem from '../../components/SidemenuItem/SidemenuItem';
import { faHouse, faGear, faIdCardClip, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../components/Logo/Logo';
import './Sidemenu.css';

import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';


const Sidemenu = () => {
    const user = useContext(UserContext);
    return (
        <div className='sidemenu'>
            <div className='sidemenu--container'>
                <div className='header--part'>
                    <Logo />
                    <div className='sidemenu--controls'>
                        {/* if there is a user */}
                        {user && <SidemenuItem label="Home" icon={faHouse} />}
                        {user && <SidemenuItem label="Settings" icon={faGear} />}
                        {user && <SidemenuItem label="Profile" icon={faIdCardClip} />}
                        {user && <SidemenuItem label="Logout" icon={faRightFromBracket} />}
                        {/* if there is no user */}
                        {!user && <SidemenuItem label="For You" />}
                        {!user && <SidemenuItem label="Discover" />}
                        {!user && <SidemenuItem label="Hire" />}
                        {!user && <SidemenuItem label="Jobs" />}
                    </div>
                </div>

                <div className='version'>
                    version 1.0.0
                </div>
            </div>
        </div>
    );
};

export default Sidemenu;