import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './GoogleLogin.css';
const GoogleLogin = () => {
    return (
        // <div className="google--login">
        //     <div className="google--icon">
        //         <FontAwesomeIcon icon={faGoogle} />
        //     </div>
        //     <div className="google--text">
        //         <span>Sign in with Google</span>
        //     </div>
        // </div>
        <div id="g_id_onload"
            data-client_id="YOUR_GOOGLE_CLIENT_ID"
            data-login_uri="https://your.domain/your_login_endpoint"
            data-your_own_param_1_to_login="any_value"
            data-your_own_param_2_to_login="any_value">
        </div>
    );
}

export default GoogleLogin;