import React from "react";

const Dropdown = ({ label, options, selected, onSelectedChange }) => {
  const renderedOptions = options.map((option) => {
    if (option.value === selected.value) {
      return null;
    }

    return (
      <li key={option.value} onClick={() => onSelectedChange(option)}>
        <a className="dropdown-item" style={{fontSize: '0.875rem'}} href="#">
          {option.label}
        </a>
      </li>
    );
  });

  return (
    <div className="btn-group">
      <button
        className="btn btn-outline-secondary btn-sm dropdown-toggle "
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selected.label}
      </button>
      <ul className="dropdown-menu">{renderedOptions}</ul>
    </div>
  );
};

export default Dropdown;
