import React from 'react';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS

const Grid = ({colDefs, data}) => {
    const columnDefs = [
        { headerName: 'Make', field: 'make' },
        { headerName: 'Model', field: 'model' },
        { headerName: 'Price', field: 'price' },
    ];

    const rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxter', price: 72000 },
    ];

    return (
        <div className="ag-theme-balham ag-theme-bars " style={{ height: 400, width: 600 }}>
            <AgGridReact
                columnDefs={colDefs}
                rowData={data}
            />
        </div>
    );
}

export default Grid;
