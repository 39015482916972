import React from "react";
import './Landing.css';
import Textbox from "../../ui_controls/textbox";
import LatestJobs from "../../components/LatestJobs/LatestJobs";

const Landing = () => {
    return (
        <div className="landing">
            <div className="landing--title">
                <h1>Find your next job</h1>
                <h2>Search and apply for jobs from your phone or computer</h2>
            </div>
            <div className="landing--search">
                <Textbox type={'text'} placeholder={'Search for jobs'} />
                <button className="btn btn-primary">Search</button>
            </div>
            <LatestJobs />
        </div>
    );
}

export default Landing;