import React from "react";
import Sidemenu from "../../containers/Sidemenu/Sidemenu";
import { Outlet } from "react-router-dom"

import "./Home.css";

const Home = () => {
  return (
    <div className="f fd-col f1 h100">
      <div className="f fd-col h100">
        {/* sidemenu */}
        <Sidemenu />
        {/* container */}
        <div className="f f1 p10 home--outlet" >
          <Outlet />
        </div>
      </div>
    </div> 
  );
};

export default Home;
