import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "./pages/Home/Home";
import UiShowCase from "./pages/UiShowCase/UiShowCase";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login/Login";
import Company, {loader as companyLoader} from "./pages/Company/Company";
import Job, {loader as jobLoader} from "./pages/Job/Job";
import Jobs from "./containers/Jobs";
import Landing from "./pages/Landing/Landing";
import { useContext } from "react";
import { UserContext } from "./contexts/UserContext";
 
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "jobs",
        element: <Jobs />,
      },
      {
        path: "job/:jobId",
        element: <Job />,
        loader: jobLoader,
        action: (context) => {
          console.log("job action", context);
        } 
      },
      {
        path: "company/:companyId",
        element: <Company />,
        loader: companyLoader
      },
    ]
  },
  {
    path: "ui-showcase",
    element: <UiShowCase />,
  },
  {
    path: "login",
    element: <Login />,
  },
]);

function App() {
  const user = useContext(UserContext);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
