import React from "react";
import './JobPoster.css'; 

/*
    jopPoster: {
        id: 1,
        name: 'User 1',
        email: 'user@domain.com',
        title: 'Senior Recruiter',
        phone: '1234567890',
        skype: '',
        linkedin: '',
        twitter: '',
        facebook: '',
        instagram: '',
        youtube: '',
        website: '',
    }
*/

const JobPoster = ({poster}) => {
    const {
        name, 
        title, 
        email, 
        phone, 
        skype, 
        linkedin, 
        twitter, 
        facebook, 
        instagram, 
        youtube, 
        website} = poster;

    return <div className="job--poster">
        <h3>Job Poster</h3>
        <div>
            <div>userprofileimg</div>
            <div>
                <div>
                    <div>{name}</div>
                </div>
                <div>{title}</div>
                <div>member since</div>
            </div> 
        </div>
    </div>;
};

export default JobPoster;
