import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

import './LatestJobs.css';

const LatestJobs = ({ jobs }) => {

    const [latestJobs, setLatestJobs] = React.useState([
        {
            id: 112332,
            title: 'Software Engineer',
            company: {
                id: 1012,
                name: 'SAP'
            },
            location: 'Zurich',
            posted: new Date("2023-01-29T15:24:00")
        },
        {
            id: 232423,
            title: 'Frontend Developer',
            company: {
                id: 8127,
                name: 'Mendex',
            },
            location: 'Neitherland',
            posted: new Date("2023-01-27T17:03:00")
        },
        {
            id: 112332,
            title: 'Software Engineer',
            company: {
                id: 1012,
                name: 'SAP'
            },
            location: 'Zurich',
            posted: new Date("2023-01-29T15:24:00")
        },
        {
            id: 232423,
            title: 'Frontend Developer',
            company: {
                id: 8127,
                name: 'Mendex',
            },
            location: 'Neitherland',
            posted: new Date("2023-01-27T17:03:00")
        },
        {
            id: 112332,
            title: 'Software Engineer',
            company: {
                id: 1012,
                name: 'SAP'
            },
            location: 'Zurich',
            posted: new Date("2023-01-29T15:24:00")
        },
        {
            id: 232423,
            title: 'Frontend Developer',
            company: {
                id: 8127,
                name: 'Mendex',
            },
            location: 'Neitherland',
            posted: new Date("2023-01-27T17:03:00")
        },
        {
            id: 112332,
            title: 'Software Engineer',
            company: {
                id: 1012,
                name: 'SAP'
            },
            location: 'Zurich',
            posted: new Date("2023-01-29T15:24:00")
        },
        {
            id: 232423,
            title: 'Frontend Developer',
            company: {
                id: 8127,
                name: 'Mendex',
            },
            location: 'Neitherland',
            posted: new Date("2023-01-31T00:03:00")
        }
    ]);
    const navigate = useNavigate();

    // function to get difference between two dates in hours if it is less than 24 hours
    // and in days if it is more than 24 hours
    const getDifferenceInHours = (date1, date2) => {
        const diffInMs = Math.abs(date2 - date1);
        const diffInHours = Math.floor(diffInMs / 1000 / 60 / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInDays} days ago`;
        }
    }
    

    useEffect(() => {
        // calling the api with latest 8 jobs query 
        if (jobs) {
            setLatestJobs(jobs);
        }
    }, []);

    // navigate to job details
    const goToJob = (id) => {
        navigate(`/job/${id}`)
    }

    // navigate to job details
    const goToCompany = (id) => {
        navigate(`/company/${id}`)
    }

    return (
        <div className="latest--jobs">
            <h3>Latest Jobs</h3>
            <div className="latest--jobs--list">
                {latestJobs.map((job, index) => {
                    return (
                        <div className="latest--job" key={index}>
                            <div className="latest--job--title" onClick={() => goToJob(job.id)}>
                                {job.title}
                            </div>
                            <div className="job--data">
                                <div className="latest--job--company" onClick={() => goToCompany(job.company.id)}>
                                    {job.company.name}
                                </div>
                                <span> - </span>
                                <div className="latest--job--location">
                                    {job.location}
                                </div>
                            </div>
                            <div className="latest--job--time">
                                {getDifferenceInHours(new Date(), job.posted)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default LatestJobs;