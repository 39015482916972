import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { getCompanyByCompanyId } from '../../mockdata/api';

export async function loader({ params }) {
    return new Promise((resolve, reject) => {
        return resolve({companyId: params.companyId});
    });
    
}

const Company = () => {

    const {companyId} = useLoaderData();
    const company = getCompanyByCompanyId(+companyId);

    return <div>
        <h1>Company {company?.name}</h1>
    </div>
};

export default Company;
