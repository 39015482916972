import React from "react";
import './textbox.css';

const Textbox = ({ label, type, placeholder, value, errors, onChange }) => {
  return (
    <div>
      {label && <label htmlFor="exampleFormControlInput1">
        {label}
      </label>}
      <input
        type={type}
        className="form-control form-control-sm textbox--input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {errors && <div className="alert alert-danger" role="alert">
        {errors.message}
      </div>}
    </div>
  );
};

export default Textbox;
