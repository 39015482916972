import React from 'react';
import { useNavigate } from "react-router-dom";
import './Logo.css';


const Logo = () => {
    // to navigate to home page
    const navigate = useNavigate();

    function handleClick() {
        navigate('/');
    }
    return (
        <div className='app-title' onClick={handleClick}>
            <span>Bay</span>
            <span className='ein'>-</span>
            <span>Bars</span>
        </div>
    );
}

export default Logo;