import React from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEllipsis, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { getJobByJobId } from '../../mockdata/api';
import './Job.css';
import JobPoster from '../../components/JobPoster/JobPoster';

export async function loader({ params }) {
    return getJobByJobId(params.jobId);
}

// export async function action() {
//     const contact = await createJob();
//     return { contact };
//   }

const Job = () => {
    const navigate = useNavigate();

    const job = useLoaderData();

    // job responsabilities
    const jobResponsabilities = job.responsabilities.map((responsability, index) => {
        return (
            <li className="job--responsability" key={index}>
                {responsability}
            </li>
        )
    });

    // job requirements
    const jobRequirements = job.requirements.map((requirement, index) => {
        return (
            <li className="job--requirement" key={index}>
                {requirement}
            </li>
        )
    });

    // job benefits
    const jobBenefits = job.benefits.map((benefit, index) => {
        return (
            <li className="job--benefit" key={index}>
                {benefit}
            </li>
        )
    });

    // copy current url to clipboard
    const copyUrlToClipboard = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
    }

    // share current url to facebook 
    const shareUrlToFacebook = () => {
        const url = window.location.href;
        const title = job.title;

        const facebookUrl = `https://www.facebook.com/dialog/feed?app_id=145634995501895&display=popup&caption=${title}&link=${url}&redirect_uri=${url}`; 
        const options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
        window.open(facebookUrl,'sharer',options);
    }

    // share current url to Twitter
    const shareUrlToTwitter = () => {
        const url = window.location.href;
        const title = job.title;
        const text = job.description;
        const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}&hashtags=${text}`;
        window.open(twitterUrl, '_blank');
    }

    // share current url to LinkedIn
    const shareUrlToLinkedIn = () => {
        const url = window.location.href;
        const title = job.title;
        const text = job.description;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${text}&source=`;
        window.open(linkedinUrl, '_blank');
    }

    return (
        // job data 
        <div className="job--container">
            <div className="job--header">
                <div className='company--logo' onClick={() => navigate(`/company/${job.company.id}`)}>
                    <img src={job.company.logo} alt="company logo" />
                </div>
                <div className='job--brief'>
                    <div className="job--title">
                        <h1>{job.company.name} - {job.title}</h1>
                    </div>
                    <div className="job--header--desc">
                        <span>{job.location}</span> <span>•</span> <span className='job--applicant'> {job.applications.length} applicants</span>
                    </div>
                </div>
            </div>
            
            <div className='job--details'>
                <div>
                    <div className='job--desc'>
                        <div className='job--desc--text'>
                            <h6>About the job</h6>
                            {job.description}
                            <ul>
                                <li>{job.contractType}</li>
                                <li>{job.jobLocation}</li>
                            </ul>
                        </div>
                        <div className="job--responsabilities">
                            <h6>Responsabilities</h6>
                            <ul>
                                {jobResponsabilities}
                            </ul>
                        </div>
                        <div className="job--requirements">
                            <h6>Requirements</h6>
                            <ul>
                                {jobRequirements}
                            </ul>
                        </div>
                        <div className="job--benefits">
                            <h6>Benefits</h6>
                            <ul>
                                {jobBenefits}
                            </ul>
                        </div>
                        <div className="company--size">
                            <FontAwesomeIcon icon={faBuilding} />
                            <span>{job.company.companySize} employees</span>
                        </div>
                    </div>
                    <JobPoster poster={job.poster}/>
                </div>
                <div className='job--details--controls'>
                    <div className="dropdown">
                        <div className='share--btn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faShareNodes} />
                        </div>
                        <ul className="dropdown-menu">
                            <li onClick={copyUrlToClipboard}>
                                <a className="dropdown-item" href="#">Copy link</a>
                            </li>
                            <li onClick={shareUrlToTwitter}>
                                <a className="dropdown-item" href="#">Twitter</a>
                            </li>
                            <li onClick={shareUrlToFacebook}>
                                <a className="dropdown-item" href="#">Facebook</a>
                            </li>
                            <li onClick={shareUrlToLinkedIn}>
                                <a className="dropdown-item" href="#">Linkedin</a>
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown">
                        <div className='menu--btn' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsis} />
                        </div>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Feedback</a></li>
                            <li><a className="dropdown-item" href="#">Report</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Job;