import React from 'react';
import {db} from '../mockdata/api';
import Grid from '../ui_controls/grid';

const Jobs = () => {

    const JobsList = db.companies.map((company) => {
        return (
            <div key={company.id}>
                <ul>
                    {company.jobs.map((job) => {
                        return (
                            <li key={job.id}>
                                <h3>{job.title}</h3>
                                <h5>{company.name}</h5>
                                <p>{job.description}</p>
                                <p>{job.location}</p>
                                <p>{job.salary}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    });

    // compine all jobs from all companies
    const allJobs = db.companies.reduce((acc, company) => {
        return acc.concat(company.jobs);
    }, []);


    const [colDefs, setColDefs] = React.useState([
        { headerName: 'Title', field: 'title' },
        { headerName: 'Desc', field: 'description' },
        { headerName: 'applications', field: 'applications.length' },
    ]);

    return (
        <div style={{width: '100%'}}>
            <h1>Jobs</h1>
            {/* <div>
                {JobsList}
            </div> */}
            <Grid colDefs={colDefs} data={allJobs}/>
        </div>
    );
};

export default Jobs;