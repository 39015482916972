// Path: src/mockdata/index.js
// getJobByJobId

export const getJobByJobId = (jobId) => {
    const job = db.companies.reduce((acc, company) => {
        const job = company.jobs.find((job) => job.id === +jobId);
        if (job) {
            acc = Object.assign(job, {company: company});
        }
        return acc;
    }, {});
    return job;
};

// getCompanyByCompanyId
export const getCompanyByCompanyId = (companyId) => {
    const company = db.companies.find((company) => company.id === companyId);
    return company;
};

// This file is used to mock data for the application
// should contain companies data , its users, jobs and applications

export const db = {
    companies: [
        {
            id: 1,
            name: 'EventsAir',
            logo: 'https://via.placeholder.com/150',
            companyAbout: 'Company 1 description',
            companySize: '100-200',
            users: [
                {
                    id: 1,
                    name: 'User 1',
                    email: ''
                },
                {
                    id: 2,
                    name: 'User 2',
                    email: ''
                }
            ],
            jobs: [
                {
                    id: 1,
                    title: 'Senior Software Engineer',
                    location: 'Brisbane, Queensland, Australia',
                    contractType: 'Full Time',
                    jobLocation: 'Remote',
                    description: "As our Senior Fullstack Developer you will work closely with the development team, the Product Manager, Project Manager and the designers to build beautiful user interfaces that bring web3 and interactive products into the mainstream. You'll be contributing to the Jungle platform and be an integral part of driving Jungle's success.",
                    responsabilities: [
                        'Build user flows across both web and backend',
                        'Collaborate with the entire team to define, prioritize, and build Web3 features',
                        'Liaise with our leadership team, designers, engineers and product owners to design robust infrastructure and applications.',
                        'Research and evaluate new methodologies, applications and technologies that improve the quality, reliability, and performance of the product.',
                        'Develop Node.JS/ NextJS/ Next.JS/ JavaScript/ React for the front-end and back-end applications',
                        'Develop unit and integration tests for core business logic',
                        'Participate in designing the architecture for new functionality and products',
                    ],
                    requirements: [
                        '6+ years in a Fullstack Development role',
                        'Experience with Node.js, GraphQL and Rest api',
                        'Experience building frontend applications using JavaScript and React. CSS would be beneficial.',
                        'Confidence in writing secure and high-quality code.',
                        'Excited to work in a fast-paced environment',
                        'Eager to learn new technologies',
                        'Familiarity with testing frameworks',
                        'Ability to work cross functionally with product, design, and development teams',
                        'Desirable that you have a good understanding of different blockchain platforms and how to sync with them',
                    ],
                    benefits: [
                        'Competitive salary',
                        'Flexible working hours',
                        'Work from home',
                        'Work with a great team',
                        'Work on a product that is changing the world',
                    ],
                    skills: [
                        'JavaScript',
                        'React',
                        'Node.js',
                        'GraphQL',
                        'CSS',
                        'HTML',
                        'Blockchain',
                        'Web3',
                        'Solidity',
                        'Ethereum',
                        'IPFS',
                        'NextJS',
                        'Next.JS',
                    ],
                    postedDate: '2020-01-01',
                    applications: [
                        {
                            id: 1,
                            user: {
                                id: 1,
                                name: 'User 1',
                                email: ''
                            },
                            status: 'applied'
                        },
                        {
                            id: 2,
                            user: {
                                id: 2,
                                name: 'User 2',
                                email: ''
                            },
                            status: 'applied'
                        }
                    ],
                    status: 'active',
                    poster: {
                        id: 1,
                        name: 'User 1',
                        email: 'user@domain.com',
                        title: 'Senior Recruiter',
                        phone: '1234567890',
                        skype: '',
                        linkedin: '',
                        twitter: '',
                        facebook: '',
                        instagram: '',
                        youtube: '',
                        website: '',
                    }
                },
                {
                    id: 2,
                    title: 'Job 2',
                    location: 'London',
                    contractType: 'Full Time',
                    description: 'Job 2 description',
                    postedDate: '2021-04-02',
                    applications: [
                        {
                            id: 3,
                            user: {
                                id: 1,
                                name: 'User 1',
                                email: ''
                            },
                            status: 'applied'
                        },
                        {
                            id: 4,
                            user: {
                                id: 2,
                                name: 'User 2',
                                email: ''
                            },
                            status: 'applied'
                        }
                    ],
                    status: 'closed'
                }
            ]
        },
        {
            id: 2,
            name: 'Company 2',
            logo: 'https://via.placeholder.com/150',
            companyAbout: 'Company 1 description',
            companySize: '10-50',
            users: [
                {
                    id: 3,
                    name: 'User 3',
                    email: ''
                },
                {
                    id: 4,
                    name: 'User 4',
                    email: ''
                }
            ],
            jobs: [
                {
                    id: 3,
                    title: 'Job 3',
                    location: 'London',
                    contractType: 'Full Time',
                    description: 'Job 3 description',
                    postedDate: '2021-03-12',
                    applications: [
                        {
                            id: 5,
                            user: {
                                id: 3,
                                name: 'User 3',
                                email: ''
                            },
                            status: 'applied'
                        },
                        {
                            id: 6,
                            user: {
                                id: 4,
                                name: 'User 4',
                                email: ''
                            },
                            status: 'applied'
                        }
                    ]
                },
                {
                    id: 4,
                    title: 'Job 4',
                    location: 'London',
                    contractType: 'Full Time',
                    description: 'Job 4 description',
                    postedDate: '2021-01-24',
                    applications: [
                        {
                            id: 7,
                            user: {
                                id: 3,
                                name: 'User 3',
                                email: ''
                            },
                            status: 'applied'
                        },
                        {
                            id: 8,
                            user: {
                                id: 4,
                                name: 'User 4',
                                email: ''
                            },
                            status: 'applied'
                        }
                    ]
                }
            ]
        }
    ]
}

