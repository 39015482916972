import React, { useState } from "react";
import Button from "../../ui_controls/button";
import Textbox from "../../ui_controls/textbox";
import Dropdown from "../../ui_controls/dropdown";

import styles from "./UiShowCase.css";

const UiShowCase = () => {
  const [selected, setSelected] = useState({
    label: "Select a color",
    value: "",
  });

  const [textValue, setTextValue] = useState("Value");
  /*
    this is a page to shocase all UI controls and test them

    UI Controls:
    - Dropdown
    - Textbox
    - Button
    */

  return (
    <div style={{ padding: 50 }}>
      <h3>UI Controls</h3>

      <h4>Dropdown</h4>
      <div className={styles.control_showcase}>
        <Dropdown
          label="Select a color"
          options={[
            {
              label: "The Color Red",
              value: "red",
            },
            {
              label: "The Color Green",
              value: "green",
            },
            {
              label: "A Shade of Blue",
              value: "blue",
            },
          ]}
          selected={selected}
          onSelectedChange={setSelected}
        />
      </div>

      <h4>Textbox</h4>
      <div className={styles.control_showcase}>
        <Textbox
          label="Label"
          type="text"
          placeholder="Placeholder"
          value={textValue}
          onChange={(e) => {
            setTextValue(e);
          }}
        />
      </div>

      <h4>Button</h4>
      <div className={styles.control_showcase}>
        <Button onClick={() => {
            console.log("Button clicked");
        }}>Button</Button>
      </div>
    </div>
  );
};

export default UiShowCase;
