import React, {useState} from 'react';

import Textbox from '../../ui_controls/textbox';
import Button from '../../ui_controls/button';

import './Login.css';
import Logo from '../../components/Logo/Logo';
import GoogleLogin from '../../components/GoogleLogin/GoogleLogin';
import FacebookLogin from '../../components/FacebookLogin/FacebookLogin';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    function handleSubmit(event) {
        event.preventDefault();
        
        if(username === '') {
            setErrors(
                Object.assign({}, errors, 
                    {
                        username:{
                            message: 'Username is required'
                        }
                    }
                )
            );  
        }

        if(password === '') {
            setErrors(
                Object.assign({}, errors, 
                    {
                        password:{
                            message: 'Password is required'
                        }
                    }
                )
            );
        }
            
    }

    return (
        <div className="login--form" role="main">
            <div className="informative">
                <Logo />
                <div className='app--desc'>
                    <h1>Manage your jobs</h1>
                    <p>Manage your jobs and get notified when they are done</p>
                </div>
            </div>
            <div className='form--container'>
                <div className="form">
                    <h3 className='form--title'>Login</h3>
                    <p>See your growth and get the best candidates ever</p>
                    <div className='social--login'>
                        <GoogleLogin />
                        <FacebookLogin />
                    </div>
                    <hr/>
                    <form className='login--controls' onSubmit={handleSubmit}>
                        <Textbox 
                            label={"Username *"}
                            value={username}
                            onChange={setUsername}
                            type={'text'}
                            placeholder={'mobile or email'}
                            errors={errors['username']}/>
                        <Textbox 
                            label={"Password *"}
                            value={password}
                            onChange={setPassword}
                            type={'password'}
                            placeholder={'password'}
                            errors={errors['password']}/>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="rememberMe" />
                            <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                        </div>
                        <Button onClick={() => console.log(`${username} - ${password}`)}>Login</Button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;