import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import './FacebookLogin.css';

const FacebookLogin = () => {
    return (
        <div className="facebook--login">
            <div className="facebook--icon">
                <FontAwesomeIcon icon={faFacebook} />
            </div>
            <div className="facebook--text">
                <span>Sign in with Facebook</span>
            </div>
        </div>
    );
}

export default FacebookLogin;